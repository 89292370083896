<template>
  <!-- table -->
  <b-overlay
    :show="loading"
    opacity="0.3"
    variant="transparent"
    spinner-variant="primary"
    spinner-type="border"
    rounded="sm"
    style="min-height: 200px;"
  >
    <vue-good-table
      v-if="trips.length > 0"
      mode="remote"
      :columns="columns"
      :rows="trips"
      :pagination-options="{
        enabled: true,
      }"
      :select-options="{
        enabled: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      @on-selected-rows-change="selectedTrip"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: ID -->
        <span
          v-if="props.column.field === '_id'"
          class="text-nowrap"
        >
          <span class="text-nowrap">{{ props.row._id }}</span>
        </span>

        <!-- Column: Fullname -->
        <span v-else-if="props.column.field === 'fullName'">
          <b-badge variant="light-info">
            {{ props.row.fullName }}
          </b-badge>
        </span>

        <!-- Column: Email -->
        <span v-else-if="props.column.field === 'email'">
          <b-badge variant="light-warning">
            {{ props.row.email }}
          </b-badge>
        </span>

        <!-- Column: Confirmed -->
        <span v-else-if="props.column.field === 'confirmed'">
          <b-badge :variant="booleanVariant(props.row.confirmed)">
            {{ props.row.confirmed }}
          </b-badge>
        </span>

        <!-- Column: V2 -->
        <span v-else-if="props.column.field === 'v2'">
          <b-badge :variant="booleanVariant(props.row.v2)">
            {{ props.row.v2 }}
          </b-badge>
        </span>

        <!-- Column: Appointment -->
        <span v-else-if="props.column.field === 'appointment'">
          <b-badge :variant="booleanVariant(props.row.appointment)">
            {{ props.row.appointment }}
          </b-badge>
        </span>

        <!-- Column: Phone -->
        <span v-else-if="props.column.field === 'phone'">
          {{ props.row.phone }}
        </span>

        <!-- Column: Destinations -->
        <span
          v-else-if="props.column.field === 'destinations'"
          v-safe-html="props.row.destinations"
        />

        <!-- Column: Duration -->
        <span v-else-if="props.column.field === 'tripDuration'">
          <b-badge variant="dark">
            {{ props.row.tripDuration }}
          </b-badge>
        </span>

        <!-- Column: Adults -->
        <span v-else-if="props.column.field === 'adults'">
          <b-badge variant="dark">
            {{ props.row.adults }}
          </b-badge>
        </span>

        <!-- Column: CreatedAt -->
        <span v-else-if="props.column.field === 'createdAt'">
          <span class="text-nowrap">{{ new Date(props.row.createdAt).toUTCString() }}</span>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'actions'">
          <span class="d-flex justify-content-around">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="btn-icon rounded-circle"
              @click="viewItem(props.row._id)"
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-secondary"
              class="btn-icon rounded-circle"
              @click="editItem(props.row._id)"
            >
              <feather-icon icon="Edit2Icon" />
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-warning"
              class="btn-icon rounded-circle"
              @click="confirmDuplicateText(props.row._id)"
            >
              <feather-icon icon="CopyIcon" />
            </b-button>
            <b-button
              variant="outline-danger"
              class="btn-icon rounded-circle"
              @click="confirmText(props.row)"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>

      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
      >
        <div class="d-flex justify-content-between flex-wrap mt-1 mb-0">
          <!-- pagination -->
          <div>
            <b-pagination
              :value="currentPage"
              :total-rows="rows.totalDocs"
              :per-page="rows.limit"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class=""
              @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>

          <div class="">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-danger"
              class="btn-icon"
              @click="batchDeleteTrips()"
            >
              <feather-icon icon="Trash2Icon" />
              Delete Selected
            </b-button>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-overlay>
</template>

<script>
import {
  BBadge, BPagination, BButton, BOverlay,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'Trips',
  components: {
    VueGoodTable,
    BBadge,
    BPagination,
    BButton,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  props: {
    page: {
      type: String,
      default: '1',
    },
  },
  data() {
    return {
      columns: [
        {
          field: '_id',
          label: 'ID',
          sortable: false,
        },
        {
          field: 'fullName',
          label: 'FullName',
          sortable: false,
        },
        {
          field: 'email',
          label: 'Email',
          sortable: false,
        },
        {
          field: 'confirmed',
          label: 'Confirmed',
          sortable: false,
        },
        {
          field: 'v2',
          label: 'isV2',
          sortable: false,
        },
        {
          field: 'appointment',
          label: 'Appointment',
          sortable: false,
        },
        {
          field: 'phone',
          label: 'Phone',
          sortable: false,
        },
        {
          field: 'destinations',
          label: 'Destinations',
          sortable: false,
        },
        {
          field: 'tripDuration',
          label: 'Trip Duration',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          field: 'adults',
          label: 'Adults',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          field: 'createdAt',
          label: 'CreatedAt',
          sortable: false,
        },
        {
          field: 'actions',
          label: 'Actions',
          sortable: false,
        },
      ],
      rows: [],
      loading: false,
      selectedTrips: [],
    }
  },
  computed: {
    booleanVariant() {
      return status => (status === true ? 'light-primary' : 'light-warning')
    },
    trips() {
      return this.rows.trips ? this.rows.trips : []
    },
    currentPage() {
      return this.page ? parseInt(this.page, 10) : 1
    },
  },
  watch: {
    // eslint-disable-next-line
    '$route.params.page': async function (page) {
      await this.loadData()
    },
  },
  async beforeMount() {
    this.loadData()
  },
  methods: {
    async loadData() {
      const self = this

      self.loading = true
      await this.$store.dispatch('app/fetchTrips', {
        page: this.currentPage,
      })
        .then(() => {
          self.rows = self.$store.state.app.trips
        })
        .catch(() => {
        })
        .finally(() => {
          self.loading = false
        })
    },
    handleChangePage(page) {
      this.$router.push({ name: 'trips', params: { page: `${page}` } })
    },
    editItem(item) {
      this.$router.push({ name: 'editTrip', params: { tripId: item } })
    },
    viewItem(item) {
      this.$router.push({ name: 'viewTrip', params: { tripId: item } })
    },
    confirmText(item) {
      const self = this

      this.$swal({
        title: 'Delete trip ?',
        text: `You are going to delete the trip with ID: ${item._id}`, /* eslint no-underscore-dangle: 0 */
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          await self.$store.dispatch('app/deleteTrip', item)
            .then(() => {
              self.$swal({
                icon: 'success',
                title: 'Deleted!',
                text: 'Trip has been deleted.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })

              self.loadData()
            })
            .catch()
        }
      })
    },
    confirmDuplicateText(item) {
      const self = this

      this.$swal({
        title: 'Duplicate trip ?',
        text: `You are going to duplicate the trip with ID: ${item}`, /* eslint no-underscore-dangle: 0 */
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, duplicate it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          await self.$store.dispatch('app/duplicateTrip', item)
            .then(() => {
              self.loadData()
              self.$swal({
                icon: 'success',
                title: 'Duplicated!',
                text: 'Trip has been duplicated.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(() => {
            })
        }
      })
    },
    selectedTrip({ selectedRows }) {
      this.selectedTrips = selectedRows.map(row => row._id)
    },
    batchDeleteTrips() {
      const self = this

      this.$swal({
        title: 'Delete trips ?',
        text: 'You are going to delete multiple trips and their emails', /* eslint no-underscore-dangle: 0 */
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          await self.$store.dispatch('app/batchDeleteTrips', self.selectedTrips)
            .then(() => {
              self.$swal({
                icon: 'success',
                title: 'Deleted!',
                text: 'Trip has been deleted.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })

              self.loadData()
            })
            .catch()
        }
      })
    },
  },
}
</script>

<style lang="scss">
</style>
